import * as React from 'react'

type PageHeaderProps = {
  heading?: string
  content?: React.ReactNode
  hasBg?: React.ReactNode
  imageBg?: React.ReactNode
  className?: string
}
const PageHeader = (props: PageHeaderProps) => {
  return (
    <>
      <section className={`section--page-header relative overflow-hidden ${props.className}`}>
        {props.imageBg && (
          <div className="absolute inset-0">
            <div className="image image--center opacity-40">
              {props.imageBg}
            </div>
          </div>
        )}

        <div className=" container--padded text-center mx-auto pb-resp-16-32 pt-resp-32-56 relative">
          <h2 className="max-w-4xl mx-auto page-header__title heading font-heading text-6xl leading-none mb-8 lg:mb-12">
            {props.heading}
          </h2>
          <div className="max-w-3xl mx-auto page-header__content text-white">
            {props.content}
          </div>
        </div>
      </section>
    </>
  )
}

export default PageHeader
