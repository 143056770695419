import * as React from 'react'
import Actions from '../Actions'

export default function CallToAction() {
  return (
    <section className="section--cta bg-accent-dark">
      <div className="container--padded py-resp-16-32 text-center">
        <div className="section--cta__inner max-w-2xl mx-auto">
          <h2 className="heading text-5xl leading-none mb-6 md:mb-10">Let's Talk</h2>
          <p className="content text-md leading-normal">
            Unleash your business digital potential through strategic digital marketing actions . Get a free, no obligation quote.
          </p>
          <Actions className="section--cta__action mt-8 md:mt-12" />
        </div>
      </div>
    </section>
  )
}
