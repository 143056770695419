import * as React from 'react'
import _ from 'lodash'
import {GatsbyImage, IGatsbyImageData} from 'gatsby-plugin-image'
interface IWorkCard {
  name: string
  excerpt: string
  categories: string[]
  image: IGatsbyImageData
}
type WorkGridProps = {
  works: IWorkCard[]
}
export default function WorkGrid({works, ...props}: WorkGridProps) {
  return (
    <>
      <section className="section--work-grid pb-resp-16-24">
        <div className="container--padded">
          <div className="work-grid__filter">
            <div className=""></div>
          </div>
          <div className="work-grid__result">
            <div className="flex flex-wrap -mx-2">
              {_.map(works, (work, i) => {
                return (
                  <div key={i} className="px-2 w-1/2 lg:w-1/3">
                    <div className="flex flex-col h-full">
                      <div className="relative overflow-hidden pt-66perc image image--center">
                        <GatsbyImage image={work.image} alt="test" className={``} />
                      </div>
                      <div className="p-4 lg:p-6 bg-theme-dark flex flex-col h-full">
                        <h4 className="mb-2 lg:mb-4 leading-none text-xl">
                          {work.name}
                        </h4>
                        <div className="">{work.excerpt}</div>
                        <div className="mt-auto">
                          {_.map(work.categories, (c, i) => {
                            return (
                              <span className="inline-block uppercase text-sm mr-3">
                                {c}
                              </span>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
