import * as React from 'react'
import Layout from '../components/layout'
import {graphql} from 'gatsby'
import SEO from '../components/seo'

import PageHeader from '../components/sections/PageHeader'
import WorkGrid from '../components/sections/WorkGrid'
import {GatsbyImage} from 'gatsby-plugin-image'
import CallToAction from '../components/sections/CallToAction'

interface IWork {
  name: string
  excerpt: string
  categories: string[]
  image: GatsbyImage | GatsbyImage[]
}

export default function OurWork(props) {
  return (
    <Layout>
      <SEO
      title="Our Work | Majiska"
      description="Our work says everything about our digital marketing expertise. Browse the delights we delivered to our clients!"
      />
      <PageHeader
        heading={`Our Work`}
        content={`Our work speaks volumes about our knowledge of digital marketing. So do our customers. The case studies below showcase the quality of outcomes accomplished by the Majiska team for businesses similar to yours.`}
      />
      <div className=""></div>
      <WorkGrid
        works={[
          {
            name: 'Sed libero',
            excerpt:
              'Quisque libero metus, condimentum nec, tempor a, commodo mollis, magna.',
            categories: ['seo'],
            image: props.data.imageCs.childImageSharp.gatsbyImageData,
          },
          {
            name: 'lorem ipsum',
            excerpt: 'Nam at tortor',
            categories: ['seo', 'adwords'],
            image: props.data.imageCs.childImageSharp.gatsbyImageData,
          },
        ]}
      />
      <CallToAction />
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    imageCs: file(relativePath: {eq: "cs1.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
  }
`
