import * as React from 'react'
import { Link } from 'gatsby'
import { useSiteMetadata } from '../hooks/useSiteMetadata'

export default function Actions(props) {
 
  const { organization } = useSiteMetadata();
  return (
    <div className={`${props.className} action flex flex-wrap justify-center  -mx-2`}>
      <div className="button w-full md:w-1/2 px-2 mb-4 md:mb-0">
        <Link to="/contact" className="btn w-full btn--large btn--primary">
          Let's Talk
        </Link>
      </div>
      <div className="button w-full md:w-1/2  px-2">
        <a
          href={`tel:${organization.phone}`}
          className="btn w-full btn--large btn--secondary btn--phone"
        >
          {organization.phone}
        </a>
      </div>
    </div>
  )
}
